export const widgetDefaults = {
  pageName: 'booking_calendar_widget',
};

export enum CalendarErrors {
  SELECTED_SLOT_VALIDATION_NO_SELECTED_LOCATION = 'selected_slot_validation_no_selected_location',
  SELECTED_SLOT_VALIDATION_NO_SELECTED_DURATION = 'selected_slot_validation_no_selected_duration',
  SELECTED_SLOT_VALIDATION_NO_SELECTED_STAFF_MEMBER = 'selected_slot_validation_no_selected_staff_member',
  SELECTED_SLOT_VALIDATION_NO_TIME_SELECTED_ERROR = 'selected_slot_validation_no_time_selected_error',
  RESCHEDULE_SERVER_ERROR = 'reschedule_server_error',
  AVAILABLE_SLOTS_SERVER_ERROR = 'available_slots_server_error',
  NO_NEXT_AVAILABLE_DATE = 'no_next_available_date',
  NEXT_AVAILABLE_DATE_SERVER_ERROR = 'next_available_date_server_error',
}

export enum Preference {
  LOCATION = 'location',
  DURATION = 'duration',
  STAFF_MEMBER = 'staffMember',
}

export enum WidgetComponents {
  TIME_PICKER = 'time_picker',
  DATE_PICKER = 'date_picker',
  BOOKING_DETAILS = 'booking_details',
  TIMEZONE_PICKER = 'timezone_picker',
  RESCHEDULE_DIALOG = 'reschedule_dialog',
  JOIN_WAITLIST_DIALOG = 'join_waitlist_dialog',
  PREMIUM_VIEWER_DIALOG = 'premium_viewer_dialog',
  FILTER = 'filter',
}

export enum WidgetElements {
  TIME_SLOT = 'time_slot',
  MONTH_ARROW = 'month_arrow',
  DATE_IN_MONTH = 'date_in_month',
  GO_TO_NEXT_AVAILABLE_DATE_LINK = 'go_to_next_available_date_link',
  CONFIRM_BUTTON = 'confirm_button',
  DROPDOWN = 'dropdown',
  CLOSE_BUTTON = 'close_button',
  CHECKBOX = 'checkbox',
  COUNTER = 'counter',
  CHECKBOX_ALL = 'checkbox_all',
  FILTER_BUTTON = 'filter_button',
  CLEAR_BUTTON = 'clear_button',
}

export enum TriggeredByOptions {
  INITIALIZE_WIDGET = 'initialize_widget',
  TIMEZONE_CHANGED = 'timezone_changed',
  DATE_SELECTED = 'date_selected',
  MONTH_SELECTED = 'month_selected',
  BOOKING_DETAILS_CLEAR_BUTTON = 'booking_details_clear_button',
  STAFF_MEMBER_BOOKING_PREFERENCE_SELECTED = 'staff_member_booking_preference_selected',
  LOCATION_BOOKING_PREFERENCE_SELECTED = 'location_booking_preference_selected',
  BOOKING_DETAILS_BOOKING_PREFERENCE_SELECTED = 'booking_details_booking_preference_selected',
  GO_TO_NEXT_AVAILABLE_DATE_LINK = 'go_to_next_available_date_link',
  TIME_SELECTED = 'time_selected',
  SUBMIT = 'submit',
  FILTER_CHANGED = 'filter_changed',
}

export enum InitializeCalendarDateOptions {
  FIRST_AVAILABLE_DATE = 'first_available_date',
  TODAY = 'today',
}

export type TimeSlotsAvailability = {
  AVAILABLE: number;
  FULL: number;
  TOO_LATE_TO_BOOK: number;
  TOO_EARLY_TO_BOOK: number;
  WAITLIST: number;
};

export const BOOKINGS_CALENDAR_REFERRAL_INFO = 'booking_calendar_widget';
