import { FilterTypes } from '../components/BookingCalendar/ViewModel/filterViewModel/filterViewModel';

export type Optional<T> = T | undefined;

export type FilterOptions = {
  [key in keyof typeof FilterTypes]: string[];
};

export enum SettingsTab {
  Manage = 'calendar_page/manage',
  Layout = 'calendar_page/layout',
  Display = 'calendar_page/display',
  Design = 'calendar_page/design',
  Text = 'calendar_page/text',
  Support = 'calendar_page/support',
}

export enum HelpArticleIds {
  Manage = 'acfff058-943d-43d2-92c7-4b6c1d47478a',
  Layout = '18119bc9-bbd8-4c60-8804-f5e7d445fdb3',
  Display = '3f84e08d-7e3c-4897-82f4-41e8cb047e24',
  Design = '58565384-f8b9-47cc-8a9f-f8eae3cd3740',
  Text = '0fd3809d-1ec8-4af2-9aec-7b1be69e412b',
  Support = '978668a8-1233-415b-8bb3-3e8c514df734',
}

export enum AlignmentOptions {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  STRETCH = 'stretch',
}

export enum SourceOptions {
  CUSTOM,
  SERVICE,
}

export enum SlotsAvailability {
  ALL,
  ONLY_AVAILABLE,
}
