import _ from 'lodash';
import { Service, StaffMember } from '@wix/bookings-uou-types';
import { CalendarState } from '../../components/BookingCalendar/controller';
import { BottomSectionStatus } from '../../components/BookingCalendar/ViewModel/widgetViewModel/widgetViewModel';
import { TimePickerStatus } from '../../components/BookingCalendar/ViewModel/timePickerViewModel/timePickerViewModel';
import { FilterTypes } from '../../components/BookingCalendar/ViewModel/filterViewModel/filterViewModel';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { Booking } from '@wix/ambassador-bookings-server/types';
import { EmptyStateType } from '../../components/BookingCalendar/ViewModel/emptyStateViewModel/emptyStateViewModel';

export function createInitialState({
  service,
  wixSdkAdapter,
  staffMembers,
  rescheduleBookingDetails,
  initialErrors,
}: {
  service?: Service;
  wixSdkAdapter: WixOOISDKAdapter;
  staffMembers?: StaffMember[];
  rescheduleBookingDetails?: Booking;
  initialErrors: EmptyStateType[];
}): CalendarState {
  let locationFilterOptions: string[] = [];
  const locationQueryParam = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.LOCATION,
  );
  if (locationQueryParam) {
    if (Array.isArray(locationQueryParam)) {
      locationFilterOptions = locationQueryParam;
    } else {
      locationFilterOptions.push(locationQueryParam);
    }
  }

  const staffFilterOptions: string[] = [];
  if (staffMembers) {
    const staffMemberId = staffMembers?.[0]?.id;
    if (
      staffMemberId &&
      _.find(service?.staffMembers, ({ id }) => id === staffMemberId)
    ) {
      staffFilterOptions.push(staffMemberId);
    }
  }

  return {
    bottomSectionStatus: BottomSectionStatus.LOADING,
    timePickerStatus: TimePickerStatus.LOADING,
    selectedService: service,
    selectedDate: undefined,
    selectedTimezone: undefined,
    selectedTime: undefined,
    availableSlots: undefined,
    selectableSlotsAtSelectedTime: undefined,
    selectedMonth: undefined,
    availableSlotsPerDay: undefined,
    selectedBookingPreferences: [],
    calendarErrors: [],
    rescheduleBookingDetails,
    dialog: undefined,
    filterOptions: {
      [FilterTypes.LOCATION]: locationFilterOptions,
      [FilterTypes.STAFF_MEMBER]: staffFilterOptions,
    },
    initialErrors,
  };
}
