import { TimezoneType } from '@wix/bookings-uou-types';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { TriggeredByOptions } from '../../../../utils/bi/consts';
import {
  getLocalTimezone,
  getStartOfMonthAsLocalDateTime,
  getTodayLocalDateTimeStartOfDay,
} from '../../../../utils/dateAndTime/dateAndTime';
import { BottomSectionStatus } from '../../ViewModel/widgetViewModel/widgetViewModel';
import { SetSelectedMonth } from '../setSelectedMonth/setSelectedMonth';
import { CalendarState } from '../../controller';
import { SetSelectedDate } from '../setSelectedDate/setSelectedDate';

export type InitializeWidget = () => Promise<void>;

export function createInitializeWidgetAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setSelectedDate: SetSelectedDate,
  setSelectedMonth: SetSelectedMonth,
): InitializeWidget {
  return async () => {
    const { wixSdkAdapter, biLogger } = context;

    if (wixSdkAdapter.isSSR()) {
      return;
    }

    const [state, setState] = getControllerState();

    const selectedTimezone = getSelectedTimezone(context);
    setState({ selectedTimezone });

    const todayLocalDateTime =
      getTodayLocalDateTimeStartOfDay(selectedTimezone);
    const startOfMonthAsLocalDateTime =
      getStartOfMonthAsLocalDateTime(todayLocalDateTime);

    await Promise.all([
      setSelectedDate(todayLocalDateTime, TriggeredByOptions.INITIALIZE_WIDGET),
      setSelectedMonth(
        startOfMonthAsLocalDateTime,
        TriggeredByOptions.INITIALIZE_WIDGET,
      ),
    ]);
    setState({ bottomSectionStatus: BottomSectionStatus.LOADED });

    biLogger.bookingsCalendarPageLoaded({});
    biLogger.bookingsCalendarBookingDetailsLoad({
      triggeredBy: TriggeredByOptions.INITIALIZE_WIDGET,
    });
    biLogger.bookingsCalendarFiltersLoad({
      triggeredBy: TriggeredByOptions.INITIALIZE_WIDGET,
      selectedFilters: JSON.stringify(state.filterOptions),
    });
  };
}

function getSelectedTimezone({ businessInfo, wixSdkAdapter }: CalendarContext) {
  const localTimezone = getLocalTimezone();
  const preSelectedTimezone = wixSdkAdapter.getUrlQueryParams().timezone;

  const isPreselectedTimezoneValid = [
    businessInfo!.timeZone,
    localTimezone,
  ].includes(preSelectedTimezone);

  const defaultTimezone =
    businessInfo!.timezoneProperties?.defaultTimezone === TimezoneType.CLIENT
      ? localTimezone
      : businessInfo!.timeZone!;

  return isPreselectedTimezoneValid ? preSelectedTimezone : defaultTimezone;
}
